<template>
    <section ref="blogHero" class="hero-wrapper" :class="CSSClasses">
        <div class="hero">
            <ImageBlock v-if="logo" :image="logo" class="logo" :noCaption="true" />
            <Typography v-else variant="h2-display">{{ title }}</Typography>
            <RichTextElements
                as="div"
                v-if="tagline"
                class="tagline"
                :elements="tagline"
                body-style="body-sans-large"
                isCentered
                :style="taglineStyle"
            />
        </div>
        <ImageBlock v-if="heroImage" :image="heroImage" type="heading" :noCaption="true" class="desktop-hero" />
        <ImageBlock
            v-if="mobileHeroImage"
            :image="mobileHeroImage"
            type="heading"
            :noCaption="true"
            class="mobile-hero"
        />
    </section>
</template>

<script setup>
import { storeToRefs } from 'pinia';

const props = defineProps({
    logo: {
        type: Object,
    },
    heroImage: {
        type: Object,
    },
    mobileHeroImage: {
        type: Object,
    },
    tagline: {
        type: Object,
    },
    title: {
        type: String,
    },
});

const CSStitle = props.title.replace(/\s/g, '');

const CSSClasses = computed(() => [
    scrollingUp.value && 'condensed fixed visible',
    !isHeaderScrolledToTop.value && 'fixed condensed',
    CSStitle,
]);

const taglineColor = inject('colorOverride', null);

const taglineStyle = computed(() => {
    if (
        props.title === 'Diwan' ||
        props.title === 'ديوان' ||
        props.title === 'China Financial Markets' ||
        props.title === 'Sada' ||
        props.title === 'صدى' ||
        props.title === 'Strategic Europe'
    ) {
        return 'color: #ffffff';
    } else {
        return 'color: #000000';
    }
});

const headerAttributesStore = useHeaderAttributesStore();
const { scrollingUp, isHeaderScrolledToTop } = storeToRefs(headerAttributesStore);

const blogHero = ref(null);
let blogHeroHeight = 0;

onMounted(() => {
    blogHeroHeight = blogHero.value.clientHeight;
    document.documentElement.style.setProperty('--blog-hero-height', `${blogHeroHeight}px`);
});
</script>

<style lang="scss" scoped>
.hero-wrapper {
    margin-top: var(--header-height);
    overflow: hidden;
    position: relative;
    transition: all ease 0.2s;

    // &.fixed {
    //     position: fixed;
    //     background: color(blanc);
    //     top: calc((var(--header-height) + var(--blog-hero-height)) * -1);
    //     width: 100%;
    //     margin-top: 0;
    //     @include z-index(modal);

    //     &.visible {
    //         top: var(--header-height);
    //     }
    // }
}

.hero {
    @include content-section;
    @include content-padding;
    @include z-index(arbitrary);
    @include padding-tb(5rem);
    position: relative;
    text-align: center;

    .logo {
        max-width: 25rem;
        margin: 0 auto;

        :deep(img) {
            max-height: 8rem;
        }
    }

    .tagline {
        margin-top: vertical-space(1);
        color: v-bind('props.accentColor');
    }

    @include media-query(phone) {
        @include padding-tb(3rem);

        .tagline {
            display: none;
        }
    }

    .condensed & {
        @include padding-tb(1.4rem);
    }
}
.StrategicEurope {
    .hero .logo {
        max-width: 35rem;
    }
}
</style>
